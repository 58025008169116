import request from '@/utils/request'

// 查询学院列表
export function listDepartment (query) {
  return request({
    url: '/rongyi/department/list',
    method: 'get',
    params: query
  })
}

// 查询学院详细
export function getDepartment (departmentId) {
  return request({
    url: '/rongyi/department/' + departmentId,
    method: 'get'
  })
}

// 新增学院
export function addDepartment (data) {
  return request({
    url: '/rongyi/department/',
    method: 'post',
    data: data
  })
}

// 修改学院
export function updateDepartment (data) {
  return request({
    url: '/rongyi/department/',
    method: 'put',
    data: data
  })
}

// 删除学院
export function delDepartment (departmentId) {
  return request({
    url: '/rongyi/department/' + departmentId,
    method: 'delete'
  })
}
